import React, { Component } from "react";

class Courses extends Component {
  state = {};
  render() {
    return <h1>Courses</h1>;
  }
}

export default Courses;
